















import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'

import ItemHierarchyGroup from '@/components/organisms/h/item/hierarchy/ItemHierarchyGroup.vue'
import ExtractConditionLayout from '@/components/organisms/h/layout/ExtractConditionLayout.vue'
import {
  NewStoreBulkOrderExtractCondition,
  itemHierarchyGroupExtractCondition,
  itemHierarchyUseList,
} from '@/types/entities'

export default defineComponent({
  components: {
    ExtractConditionLayout,
    ItemHierarchyGroup,
  },
  props: {},
  setup(_, context) {
    /*********************************
     * 表示する選択肢
     *********************************/
    // Todo: TanaUseListにクラス、カテゴリ追加
    const itemStoreUseList = ref<itemHierarchyUseList>([
      ['Chubunrui', 'Shobunrui', 'Class', 'Category'],
    ])
    /*********************************
     * データ
     *********************************/
    const extractConditionOfItemStore = ref<itemHierarchyGroupExtractCondition>(
      {}
    )

    /*********************************
     * emitデータの作成
     *********************************/
    // いずれかの抽出条件変更時の処理
    const changeExtractCondition = () => {
      const base: NewStoreBulkOrderExtractCondition = {
        chubunrui: extractConditionOfItemStore.value.chubunrui || [],
        chubunruiCount: extractConditionOfItemStore.value.chubunruiCount || 0,
        shobunrui: extractConditionOfItemStore.value.shobunrui || [],
        shobunruiCount: extractConditionOfItemStore.value.shobunruiCount || 0,
        class: extractConditionOfItemStore.value.class || [],
        classCount: extractConditionOfItemStore.value.classCount || 0,
        category: extractConditionOfItemStore.value.category || [],
        categoryCount: extractConditionOfItemStore.value.categoryCount || 0,
      }

      let cond = { ...base }
      // console.log('changeExtractCondition', { cond })
      context.emit('change-extract-condition', cond)
    }

    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/
    const changeExtractConditionOfItemStore = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      console.log('changeExtractConditionOfItemStore: { params }')
      extractConditionOfItemStore.value = params
      changeExtractCondition()
    }

    const requiredItemInput = [
      '中分類, 小分類, クラス, カテゴリのいずれかを一つ以上選択',
    ]
    return {
      requiredItemInput,
      itemStoreUseList,
      changeExtractConditionOfItemStore,
    }
  },
})
