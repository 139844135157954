
















import { defineComponent } from '@vue/composition-api'

import PageView from '@/components/templates/h/new-store-bulk-order/new-store-bulk-order/PageView.vue'
import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import { 
  NewStoreBulkOrderExtractCondition,
  NewStoreBulkOrderOutputCondition,
  ColumnLabel 
} from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
  VUiPNewStoreBulkOrdersCountDocument,
} from '@/types/generated/graphql'
import { EXPORT_FILENAME,
  OUTPUT_EXTRACT_TARGET_DEFAULT,
  SELECT_MAX_COUNT, 
} from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
const DEFAULT_OUTPUT_CONST = {
  extractTarget: OUTPUT_EXTRACT_TARGET_DEFAULT, // 出力条件（抽出対象）はデフォルト値を設定できるようにしていないため、定数を用意した
  delOutput: [],
}

export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      resourceType: ResourceType.UiPNewStoreBulkOrder,
      templateFile: TemplateFile.UiPNewStoreBulkOrder_01,
      extractCondition: {
      },
      totalCount: null,
      exportSpinner: false,
      exportCondition: false,
      outputCondition: DEFAULT_OUTPUT_CONST,

    }
  },
  methods: {
    changeExtractCondition(cond: NewStoreBulkOrderExtractCondition) {
      console.log('NewStoreBulkOrderExtractCondition', cond)
      this.extractCondition = cond
      this.requestCount()
    },
    changeOutputCondition(cond: NewStoreBulkOrderOutputCondition) {
      console.log('changeOutputCondition', cond)
      this.outputCondition = cond
      this.requestCount()
    },
    async requestCount() {
      this.exportSpinner = true
      this.totalCount = null
      if (this.checkRequired(false)) {
        try {
          const result = await this.$apollo.query({
            query: VUiPNewStoreBulkOrdersCountDocument,
            variables: { where: null/*this._createWhere()*/ },
          })
          const data = result.data
          this.totalCount = data.vUiPNewStoreBulkOrders.totalCount.toString()
          this.exportSpinner = false
        } catch (e) {
          console.error(e)
          this.exportSpinner = false
          this.totalCount = '-'
        }
      } else {
        this.exportSpinner = false
        this.totalCount = '-'
      }
    },    
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({ files: files, resourceType: this.resourceType })
    },
    /*********************************
     * 以下はエクスポートリクエスト用の処理
     *********************************/
    //　入力チェック
    checkRequired(showToast: boolean) {
      return true
    },    
    /**
     * エクスポート処理
     */
    async requestExport() {
      const query = this._createQueryString()
      console.log('requestExport {query}')
      await this.helper.export({
        query,
        jmespathQuery:
          'data.vUiPNewStoreBulkOrders.edges[].node | map(&merge(`{"__update": null}`, @), @)',
        templateFile: this.templateFile,
        filenameExport: EXPORT_FILENAME.new_store_bulk_order,
        columnLabelMapping: this._create_column_label_mapping(),
      })
    },
    /**
     * ファイル抽出Queryを構築する
     */
    _createQueryString(): string {
      const orderBy = this._createOrderByStatement()
      const where: Where[] = this._createWhere()
      const rowQuery = `
        query c {
          vUiPNewStoreBulkOrders(
            where: ${this.helper.generateQueryWhereString(where)}
            orderBy: ${this.helper.generateQueryOrderByString(orderBy)}
          ) {
            edges {
              node {
                inactiveFlg
                paramApplyLevel
                category1Cd
                category1Name
                category2Cd
                category2Name
                category3Cd
                category3Name
                rsvParam1
                rsvParam2
                rsvParam16
                rsvParam3
                rsvParam4
                rsvParam5
                rsvParam6
                rsvParam7
                rsvParam8
                rsvParam9
                rsvParam10
                rsvParam11
                rsvParam12
                rsvParam13
                rsvParam14
                rsvParam15
                rsvParam17
                rsvParam18
                rsvParam19
                rsvParam20
                rsvParam21
                rsvParam22
                rsvParam23
                rsvParam24
                rsvParam25
                rsvParam26
                rsvParam27
                rsvParam28
                rsvParam29
                rsvParam30
                rsvParam31
                lastModifiedDatetime
                lastModifiedUserName
              }
            }
          }
        }
        `
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where: where,
      })
    },
    /**
     * ファイル抽出QueryのWhereパラメータを構築する
     */
     _createWhere() {
      const ITEM_SEARCH_CONDITION: Where[] = [
        // 抽出条件
        { field: ['category0Cd'], op: WhereOp.In, value: [''] },
        { field: ['category1Cd'], op: WhereOp.In, value: [''] },
        { field: ['category2Cd'], op: WhereOp.In, value: [''] },
        { field: ['category3Cd'], op: WhereOp.In, value: [''] },
        { field: ['inactiveFlg'], op: WhereOp.In, value: [''] },
      ]
      const where = JSON.parse(JSON.stringify(ITEM_SEARCH_CONDITION))
      setWhereValue(where, 'category0Cd', this.extractCondition.chubunrui)
      setWhereValue(where, 'category1Cd', this.extractCondition.shobunrui)
      setWhereValue(where, 'category2Cd', this.extractCondition.class)
      setWhereValue(where, 'category3Cd', this.extractCondition.category)
      setWhereValue(where, 'inactiveFlg', [
        '0', // 削除されていないデータは必ず出力する
        ...this.outputCondition.delOutput,
      ])
      console.log("extractCondition", this.extractCondition)
      console.log("----- _createWhere")
      console.log({ where })
      return where
    },
    /**
     * ファイル抽出QueryのOrderByパラメータを構築する
     */
    _createOrderByStatement(): OrderBy[] {
      return [
        {
          field: 'paramApplyLevel',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'category1Cd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'category2Cd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'category3Cd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
    },
    _create_column_label_mapping() {
      const columnLabelMapping: ColumnLabel[] = [
        {
          from: '__update',
          to: '',
        },
        {
          from: 'inactiveFlg',
          to: '',
        },
        {
          from: 'paramApplyLevel',
          to: '',
        },
        {
          from: 'paramApplyLevelName',
          to: '',
        },
        {
          from: 'category1Cd',
          to: '',
        },
        {
          from: 'category1Name',
          to: '',
        },
        {
          from: 'category2Cd',
          to: '',
        },
        {
          from: 'category2Name',
          to: '',
        },
        {
          from: 'category3Cd',
          to: '',
        },
        {
          from: 'category3Name',
          to: '',
        },        
        {
          from: 'rsvParam1',
          to: '',
        },
        {
          from: 'rsvParam2',
          to: '',
        },
        {
          from: 'rsvParam16',
          to: '',
        },
        {
          from: 'rsvParam3',
          to: '',
        },
        {
          from: 'rsvParam4',
          to: '',
        },
        {
          from: 'rsvParam5',
          to: '',
        },
        {
          from: 'rsvParam6',
          to: '',
        },
        {
          from: 'rsvParam7',
          to: '',
        },
        {
          from: 'rsvParam8',
          to: '',
        },
        {
          from: 'rsvParam9',
          to: '',
        },
        {
          from: 'rsvParam10',
          to: '',
        },
        {
          from: 'rsvParam11',
          to: '',
        },
        {
          from: 'rsvParam12',
          to: '',
        },
        {
          from: 'rsvParam13',
          to: '',
        },
        {
          from: 'calcTypeName',
          to: '',
        },
        {
          from: 'rsvParam14',
          to: '',
        },
        {
          from: 'rsvParam15',
          to: '',
        },
        {
          from: 'rsvParam17',
          to: '',
        },
        {
          from: 'rsvParam18',
          to: '',
        },
        {
          from: 'rsvParam19',
          to: '',
        },
        {
          from: 'rsvParam20',
          to: '',
        },
        {
          from: 'rsvParam21',
          to: '',
        },
        {
          from: 'rsvParam22',
          to: '',
        },
        {
          from: 'rsvParam23',
          to: '',
        },
        {
          from: 'rsvParam24',
          to: '',
        },
        {
          from: 'rsvParam25',
          to: '',
        },
        {
          from: 'rsvParam26',
          to: '',
        },
        {
          from: 'rsvParam27',
          to: '',
        },
        {
          from: 'rsvParam28',
          to: '',
        },
        {
          from: 'rsvParam29',
          to: '',
        },
        {
          from: 'rsvParam30',
          to: '',
        },
        {
          from: 'rsvParam31',
          to: '',
        },
        {
          from: 'calcTypeName',
          to: '',
        },
        {
          from: 'lastModifiedDatetime',
          to: '',
        },
        {
          from: 'lastModifiedUserName',
          to: '',
        },
      ]
      console.log({ columnLabelMapping })
      return columnLabelMapping
    },
  },
})
